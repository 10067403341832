import React from 'react';
import Portfolio from './Portfolio';

function App() {
  return (
      <div className="App">
        <Portfolio />
      </div>
  );
}

export default App;
